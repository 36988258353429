import React from 'react';
import { SignUpButton, useUser } from '@clerk/clerk-react';
import { homeConfig } from '../../config/HomeConfig';
import { PRICING_TIERS } from '../../config/stripe';

type PricingTier = keyof typeof PRICING_TIERS;

interface PricingProps {
  id?: string;
}

const PricingPlan: React.FC<{ tier: PricingTier }> = ({ tier }) => {
  const { minutes, price, discount } = PRICING_TIERS[tier];
  const { isSignedIn, isLoaded } = useUser();

  const originalPrice = discount > 0 ? Math.round(price / (1 - discount / 100)) : price;
  const savings = originalPrice - price;

  const planNames = {
    BASIC: "Quick Check-In",
    STANDARD: "Heart-to-Heart",
    PREMIUM: "Deep Dive"
  };

  const planDescriptions = {
    BASIC: "Perfect for quick advice or a brief chat",
    STANDARD: "Ideal for more in-depth conversations",
    PREMIUM: "For those who want extended guidance"
  };

  return (
    <div className={`${homeConfig.pricing.cardBackgroundColor} p-8 rounded-lg shadow-md flex flex-col h-full ${tier === 'STANDARD' ? homeConfig.pricing.highlightColor : 'border-transparent'} hover:border-primary transition-all duration-300`}>
      {tier === 'STANDARD' && (
        <div className="bg-primary text-primary-foreground px-4 py-1 rounded-full text-sm font-semibold mb-4 self-start">
          Most Popular
        </div>
      )}
      <h3 className="text-2xl font-semibold mb-2">{planNames[tier as keyof typeof planNames]}</h3>
      <p className="text-muted-foreground mb-4">{planDescriptions[tier as keyof typeof planDescriptions]}</p>
      <div className="mb-6">
        <p className="text-4xl font-bold">${price}</p>
        {discount > 0 && (
          <p className="text-sm text-muted-foreground line-through">${originalPrice}</p>
        )}
        <p className="text-muted-foreground">for {minutes} minutes of conversation</p>
      </div>
      {discount > 0 && (
        <div className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-semibold mb-6 self-start">
          Save {discount}% (${savings})
        </div>
      )}
      <ul className="mb-8 flex-grow">
        <li className="mb-2 flex items-start">
          <span className="text-primary mr-2">✓</span>
          <span>Access to all AI advisors</span>
        </li>
        <li className="mb-2 flex items-start">
          <span className="text-primary mr-2">✓</span>
          <span>Available 24/7, whenever you need to talk</span>
        </li>
        <li className="mb-2 flex items-start">
          <span className="text-primary mr-2">✓</span>
          <span>Minutes never expire</span>
        </li>
      </ul>
      {isLoaded && (
        isSignedIn ? (
          <button 
            className="w-full py-3 rounded-lg transition duration-300 bg-primary text-primary-foreground hover:bg-primary/90 font-semibold"
          >
            Choose This Plan
          </button>
        ) : (
          <SignUpButton mode="modal">
            <button className="w-full py-3 rounded-lg transition duration-300 bg-primary text-primary-foreground hover:bg-primary/90 font-semibold">
              Start Talking
            </button>
          </SignUpButton>
        )
      )}
    </div>
  );
};

export const Pricing: React.FC<PricingProps> = ({ id = 'pricing' }) => {
  return (
    <section id={id} className={`py-20 ${homeConfig.pricing.backgroundColor}`}>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-4">Choose Your Conversation Plan</h2>
        <p className="text-xl text-center text-muted-foreground mb-12">Select the perfect amount of talk time with our AI advisors</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {(Object.keys(PRICING_TIERS) as PricingTier[]).map((tier) => (
            <PricingPlan key={tier} tier={tier} />
          ))}
        </div>
        <p className="text-center mt-12 text-muted-foreground">All plans give you full access to our AI advisor team. Choose your plan and start talking about what matters to you!</p>
      </div>
    </section>
  );
};