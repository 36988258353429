import { useUser, useAuth as useClerkAuth } from '@clerk/clerk-react';

const useAuth = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  const { signOut } = useClerkAuth();

  return {
    isAuthenticated: isSignedIn,
    user,
    isLoading: !isLoaded,
    signOut,
  };
};

export default useAuth;