import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';

interface UserData {
  minutesRemaining: number;
  purchaseHistory: Array<{
    date: string;
    minutes: number;
    price: number;
  }>;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useUserData = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { getToken } = useAuth();

  const fetchUserData = useCallback(async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const response = await axios.get<UserData>(`${API_BASE_URL}/api/user/user-data`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch user data');
    } finally {
      setLoading(false);
    }
  }, [getToken]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const checkMinutes = async (minutesRequired: number) => {
    try {
      const token = await getToken();
      const response = await axios.post(
        `${API_BASE_URL}/api/user/check-minutes`,
        { minutesRequired },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchUserData(); // Refresh user data after checking minutes
      return response.data;
    } catch (err) {
      console.error('Error checking minutes:', err);
      throw err;
    }
  };

  const canMakeCall = async (minutesRequired: number): Promise<boolean> => {
    try {
      const result = await checkMinutes(minutesRequired);
      if (!result.canMakeCall) {
        alert(`Insufficient minutes. You need at least ${minutesRequired} minutes to start a call. You currently have ${result.minutesRemaining.toFixed(2)} minutes remaining.`);
      }
      return result.canMakeCall;
    } catch (err) {
      console.error('Error checking if call can be made:', err);
      alert('An error occurred while checking your available minutes. Please try again.');
      return false;
    }
  };

  return { userData, loading, error, checkMinutes, canMakeCall };
};