import React from 'react';
import { SignUp } from '@clerk/clerk-react';

const Register: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <img src="/assets/logo-short-white.png" alt="Logo" className="mb-8 w-24" />
      <SignUp 
        path="/register" 
        signInUrl="/login"  // No `routing="path"` here
      />
    </div>
  );
};

export default Register;
