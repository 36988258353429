import Vapi from '@vapi-ai/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useUser } from '@clerk/clerk-react'; // Import useUser hook from Clerk

const publicKey = process.env.REACT_APP_VAPI_PUBLIC_KEY || '';

const useVapi = () => {
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [conversation, setConversation] = useState<any[]>([]);
  const vapiRef = useRef<Vapi | null>(null);
  const { user } = useUser();

  const initializeVapi = useCallback(() => {
    if (!vapiRef.current && publicKey) {
      const vapiInstance = new Vapi(publicKey);
      vapiRef.current = vapiInstance;

      vapiInstance.on('call-start', () => {
        console.log('Call has started');
        setIsSessionActive(true);
      });

      vapiInstance.on('call-end', () => {
        console.log('Call has stopped');
        setIsSessionActive(false);
        setConversation([]);
      });

      vapiInstance.on('speech-start', () => {
        //console.log('Speech has started');
      });

      vapiInstance.on('speech-end', () => {
        //console.log('Speech has ended');
      });

      vapiInstance.on('volume-level', (volume: number) => {
        //console.log(`Assistant volume level: ${volume}`);
        setVolumeLevel(volume);
      });

      vapiInstance.on('message', (message: any) => {
        //console.log('Received message:', message);
        if (
          message.type === 'transcript' &&
          message.role &&
          message.transcript
        ) {
          setConversation((prev) => [
            ...prev,
            {
              role: message.role,
              text: message.transcript,
              timestamp: new Date().toLocaleTimeString(),
            },
          ]);
        }
      });

      vapiInstance.on('error', (e: Error) => {
        console.error('Vapi error:', e);
      });
    }
  }, []);

  useEffect(() => {
    initializeVapi();

    return () => {
      if (vapiRef.current) {
        vapiRef.current.stop();
        vapiRef.current = null;
      }
    };
  }, [initializeVapi]);

  const toggleCall = useCallback(
    async (assistantConfig?: any) => {
      try {
        if (isSessionActive && vapiRef.current) {
          await vapiRef.current.stop();
          setIsSessionActive(false);
        } else if (vapiRef.current && assistantConfig) {
          const userPreferences = localStorage.getItem('userPreferences') || '';
          
          const assistantOverrides = {
            model: {
              provider: assistantConfig.model.provider,
              model: assistantConfig.model.model,
              messages: [
                ...assistantConfig.model.messages,
                {
                  role: "user",
                  content: `The user's preferences that they've set in the TMAI dashboard. Please use these to tailor your responses: ${userPreferences}`,
                },
              ],
            },
            metadata: {
              clerkUserId: user?.id || '',
            },
          };

          // Start the call with assistantOverrides
          await vapiRef.current.start(assistantConfig, assistantOverrides);
          setIsSessionActive(true);
        }
      } catch (err) {
        console.error('Error toggling Vapi session:', err);
        setIsSessionActive(false);
      }
    },
    [isSessionActive, user]
  );
  

  const toggleMute = useCallback(() => {
    if (vapiRef.current) {
      const newMuteState = !isMuted;
      vapiRef.current.setMuted(newMuteState);
      setIsMuted(newMuteState);
    }
  }, [isMuted]);

  return {
    volumeLevel,
    isSessionActive,
    conversation,
    toggleCall,
    toggleMute,
    isMuted,
  };
};

export default useVapi;