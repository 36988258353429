// src/pages/auth/Dashboard.tsx

import React, { useState } from 'react';
import { AssistantCard } from '../../components/AssistantCard';
import AssistantCreator from '../../components/AssistantCreator';
import { WellnessAssistant } from '../../assistants/WellnessAssistant';
import { FinancialAdvisorAssistant } from '../../assistants/FinancialAdvisorAssistant';
import { CareerCoachAssistant } from '../../assistants/CareerCoachAssistant';
import { RelationshipAdvisorAssistant } from '../../assistants/RelationshipAdvisorAssistant';
import { KnowledgeGuideAssistant } from '../../assistants/KnowledgeGuideAssistant';
import { LifeBalanceCoachAssistant } from '../../assistants/LifeBalanceCoachAssistant';
import { MentalHealthSupportAssistant } from '../../assistants/MentalHealthSupportAssistant';
import { BusinessAdvisorAssistant } from '../../assistants/BusinessAdvisorAssistant';
import { ParentingCoachAssistant } from '../../assistants/ParentingCoachAssistant';
import { LegalAdvisorAssistant } from '../../assistants/LegalAdvisorAssistant';
import { TechSupportAssistant } from '../../assistants/TechSupportAssistant';
import { CreativeMentorAssistant } from '../../assistants/CreativeMentorAssistant';
import { AssistantUIConfig } from '../../config/AssistantUIConfig';
import useVapi from '../../hooks/useVapi';
import PreferencesModal from '../../components/PreferencesModal';
import { AssistantCategory } from '../../types/AssistantCategories';
import { TMAIAssistant, VapiAssistantConfig } from '../../types/AssistantTypes';
import { useUserData } from '../../hooks/useUserData'; // Add this line
import PurchaseModal from '../../components/PurchaseModal';


type AssistantWithUI = TMAIAssistant & {
  ui: {
    role: string;
    description: string;
    avatar: string;
  };
};

const combineAssistantWithUI = (assistant: TMAIAssistant, uiConfig: any): AssistantWithUI => ({
  ...assistant,
  ui: uiConfig,
});

const predefinedAssistants: AssistantWithUI[] = [
  combineAssistantWithUI(WellnessAssistant, AssistantUIConfig.WellnessAssistant),
  combineAssistantWithUI(MentalHealthSupportAssistant, AssistantUIConfig.MentalHealthSupportAssistant),
  combineAssistantWithUI(RelationshipAdvisorAssistant, AssistantUIConfig.RelationshipAdvisorAssistant),
  combineAssistantWithUI(LifeBalanceCoachAssistant, AssistantUIConfig.LifeBalanceCoachAssistant),
  combineAssistantWithUI(KnowledgeGuideAssistant, AssistantUIConfig.KnowledgeGuideAssistant),
  combineAssistantWithUI(CareerCoachAssistant, AssistantUIConfig.CareerCoachAssistant),
  combineAssistantWithUI(FinancialAdvisorAssistant, AssistantUIConfig.FinancialAdvisorAssistant),
  combineAssistantWithUI(ParentingCoachAssistant, AssistantUIConfig.ParentingCoachAssistant),
  combineAssistantWithUI(CreativeMentorAssistant, AssistantUIConfig.CreativeMentorAssistant),
  combineAssistantWithUI(BusinessAdvisorAssistant, AssistantUIConfig.BusinessAdvisorAssistant),
  combineAssistantWithUI(TechSupportAssistant, AssistantUIConfig.TechSupportAssistant),
  combineAssistantWithUI(LegalAdvisorAssistant, AssistantUIConfig.LegalAdvisorAssistant),
];


const Dashboard: React.FC = () => {
  const [activeAssistant, setActiveAssistant] = useState<string | null>(null);
  const [loadingAssistant, setLoadingAssistant] = useState<string | null>(null);
  const [isPreferencesModalOpen, setPreferencesModalOpen] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<AssistantCategory | 'All'>('All');
  const [showCreator, setShowCreator] = useState(false);
  const [assistants, setAssistants] = useState<AssistantWithUI[]>(predefinedAssistants);
  const { toggleCall } = useVapi();
  const { userData, canMakeCall } = useUserData();

  const handleStartAssistant = async (assistant: AssistantWithUI) => {
    if (activeAssistant) {
      // A call is already in progress
      if (activeAssistant === assistant.name) {
        // User clicked on the active assistant, end the call
        await toggleCall();
        setActiveAssistant(null);
        setLoadingAssistant(null);
        // Refresh user data after ending the call
        await canMakeCall(0);
      } else {
        // User clicked on a different assistant while a call is in progress
        const confirmEnd = window.confirm("You're already in a call. You need to end the current call before starting a new one. Do you want to end the current call?");
        if (confirmEnd) {
          // End the current call
          await toggleCall();
          setActiveAssistant(null);
          setLoadingAssistant(null);
          // Refresh user data after ending the call
          await canMakeCall(0);
        }
      }
    } else {
      // No active call, proceed with existing logic to start a new call
      const MIN_CALL_DURATION = 0.5; // 30 seconds in minutes
      if (!userData || userData.minutesRemaining < MIN_CALL_DURATION) {
        setIsPurchaseModalOpen(true);
        return;
      }

      const canProceed = await canMakeCall(MIN_CALL_DURATION);
      if (!canProceed) {
        return;
      }

      setLoadingAssistant(assistant.name);
      const vapiConfig: VapiAssistantConfig = {
        transcriber: assistant.transcriber,
        model: assistant.model,
        voice: assistant.voice,
        name: assistant.name,
        firstMessage: assistant.firstMessage,
        endCallMessage: assistant.endCallMessage,
        endCallPhrases: assistant.endCallPhrases
      };
      
      try {
        await toggleCall(vapiConfig);
        setActiveAssistant(assistant.name);
      } catch (error) {
        console.error('Error starting new call:', error);
        alert('Failed to start the call. Please try again.');
        setActiveAssistant(null);
      } finally {
        setLoadingAssistant(null);
      }
    }
  };

  const handleSavePreferences = (preferences: string) => {
    //console.log('Preferences saved:', preferences);
    localStorage.setItem('userPreferences', preferences);
  };

  const handleCreateAssistant = (newAssistant: TMAIAssistant) => {
    const newAssistantWithUI: AssistantWithUI = {
      ...newAssistant,
      ui: {
        role: newAssistant.model.messages[0].content.split('.')[0],
        description: newAssistant.model.messages[0].content.split('.').slice(1).join('.').trim(),
        avatar: `/assets/images/${newAssistant.category.toLowerCase()}_assistant.png`,
      },
    };
    setAssistants([...assistants, newAssistantWithUI]);
    setShowCreator(false);
  };

  const filteredAssistants = selectedCategory === 'All'
    ? assistants
    : assistants.filter(a => a.category === selectedCategory);

  return (
    <>
      <div className="fixed inset-0 bg-gray-100 dark:bg-gray-900 z-[-1]"></div>
      <div className="relative min-h-screen flex flex-col">
        <div className="flex-grow container mx-auto px-4 py-8">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Advisor Dashboard</h1>
            <div>
              <button
                onClick={() => setPreferencesModalOpen(true)}
                className="border border-primary text-primary dark:border-white dark:text-white px-4 py-2 rounded-md hover:bg-primary hover:text-white dark:hover:bg-white/10 transition duration-300"
              >
                Personalize
              </button>
            </div>
          </div>
          
          {showCreator ? (
            <AssistantCreator
              onCreateAssistant={handleCreateAssistant}
              onCancel={() => setShowCreator(false)}
            />
          ) : (
            <>
              <div className="mb-4">
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value as AssistantCategory | 'All')}
                  className="p-2 border rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600 focus:ring-primary focus:border-primary"
                >
                  <option value="All">All Categories</option>
                  {Object.values(AssistantCategory).map((category) => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredAssistants.map((assistant, index) => (
                  <AssistantCard
                    key={index}
                    assistant={assistant}
                    isActive={activeAssistant === assistant.name}
                    isLoading={loadingAssistant === assistant.name}
                    onCallInitiate={handleStartAssistant}
                  />
                ))}
              </div>
            </>
          )}
        </div>

        <footer className="mt-auto py-4">
          <div className="container mx-auto px-4">
            <div className="rounded-md border bg-white dark:bg-gray-800 p-4">
              <p className="text-sm text-gray-700 dark:text-gray-300">
                Our AI advisors are available 24/7, ready to listen and offer fresh perspectives on whatever life throws your way. However, please remember that while our AI advisors provide supportive conversations, they do not offer professional advice. For serious concerns, always consult with qualified professionals. By engaging with our AI advisors, you're agreeing to our{' '}
                <a
                  href="/terms-of-service"
                  className="text-primary hover:underline dark:text-primary-light"
                >
                  Terms of Service
                </a>
                {' '}and{' '}
                <a
                  href="/privacy-policy"
                  className="text-primary hover:underline dark:text-primary-light"
                >
                  Privacy Policy
                </a>
                . We hope you find your conversations insightful and supportive! Email us your feedback at{' '}
                <a
                  href="mailto:contact@tellmeaboutit.ai"
                  className="text-primary hover:underline dark:text-primary-light"
                >
                  contact@tellmeaboutit.ai
                </a>
                .
              </p>
            </div>
          </div>
        </footer>
      </div>

      <PreferencesModal
        isOpen={isPreferencesModalOpen}
        onClose={() => setPreferencesModalOpen(false)}
        onSave={handleSavePreferences}
      />

      <PurchaseModal
        isOpen={isPurchaseModalOpen}
        onClose={() => setIsPurchaseModalOpen(false)}
        initialMessage={
          userData && userData.minutesRemaining < 0.5
            ? `You currently have ${userData.minutesRemaining.toFixed(2)} minutes. Please purchase minutes to start a call.`
            : undefined
        }
      />
    </>
  );
};

export default Dashboard;