// src/hooks/useStripe.ts
import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useStripe = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { getToken } = useAuth();

  const createCheckoutSession = async (priceId: string) => {
    setLoading(true);
    setError(null);
    try {
      const token = await getToken();
      const response = await axios.post(
        `${API_BASE_URL}/api/subscription/create-checkout-session`,
        { priceId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('No checkout URL received from server');
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
      setError('Failed to create checkout session. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return { createCheckoutSession, loading, error };
};