import React, { useState } from 'react';
import { homeConfig } from '../../config/HomeConfig';
import { ChevronDown } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "What is Tell Me About It?",
    answer: "Tell Me About It (TMAI) is a 24/7 AI voice advisor service offering personalized, empathetic conversations. Our AI-powered advisors are available anytime to discuss a variety of topics, providing support and insights that complement human interactions."
  },
  {
    question: "What can I talk about with TMAI?",
    answer: "You can talk about anything on your mind, from wellness and career development to relationships, life balance, knowledge exploration, and creative thinking. Our AI advisors are here to listen, provide support, and help you gain new perspectives."
  },
  {
    question: "Are my conversations private?",
    answer: "We take your privacy seriously. While we use third-party services to help deliver and improve our AI advisors, i.e. voice transcription and language processing, your conversations are encrypted and protected. Your data is only used to provide our service, and your interactions are used anonymously to enhance the overall user experience."
  },
  {
    question: "Can I choose different advisors for different topics?",
    answer: "Absolutely! We offer a range of specialized AI advisors, each focusing on different areas like wellness, relationships, career, and more. You can switch between advisors anytime to get tailored guidance on the specific topics you want to explore."
  },
  {
    question: "How is this different from a regular chatbot?",
    answer: "TMAI goes beyond a typical chatbot by offering AI advisors that engage in meaningful, context-aware conversations. Our advisors are designed to provide a more natural, empathetic interaction, helping you explore your thoughts and gain deeper insights."
  },
  {
    question: "What if I need help with a serious issue?",
    answer: "While our AI advisors provide valuable support and guidance, they are not a replacement for professional services. For serious medical, mental health, legal, or financial issues, we strongly recommend consulting with a qualified human professional who can offer expert advice and assistance."
  }
];

const FAQItemComponent: React.FC<{ item: FAQItem; isOpen: boolean; toggleItem: () => void }> = ({ item, isOpen, toggleItem }) => (
  <div className="border-b border-gray-200 dark:border-gray-700">
    <button
      className="flex justify-between items-center w-full py-4 text-left"
      onClick={toggleItem}
    >
      <span className="text-lg font-semibold">{item.question}</span>
      <ChevronDown className={`w-5 h-5 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} />
    </button>
    <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
      <p className="py-4 text-muted-foreground">{item.answer}</p>
    </div>
  </div>
);

export const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="faq" className={`py-20 ${homeConfig.faq.backgroundColor}`}>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto">
          {faqData.map((item, index) => (
            <FAQItemComponent
              key={index}
              item={item}
              isOpen={openIndex === index}
              toggleItem={() => toggleItem(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};