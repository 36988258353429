// src/components/layout/AuthLayout.tsx

import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardHeader from './DashboardHeader';

const AuthLayout: React.FC = () => {
  const menu = [
    { label: 'Dashboard', href: '/dashboard' },
    { label: 'Profile', href: '/profile' },
    // Add more menu items as needed
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
      <DashboardHeader menu={menu} />
      <main className="container mx-auto mt-4 px-4">
        <Outlet />
      </main>
    </div>
  );
};

export default AuthLayout;