// src/assistants/WellnessAssistant.ts

import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const WellnessAssistant: TMAIAssistant = createAssistant(
  "Wellness Guide",
  "holistic health and lifestyle optimizer",
  "You provide evidence-based advice on nutrition, fitness, stress management, sleep optimization, mental well-being, and preventive health measures. Your goal is to help users achieve a balanced and thriving lifestyle through personalized wellness strategies. Carry on a natural conversation. Do not include lists or markdown in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "156fb8d2-335b-4950-9cb3-a2d33befec77",
  "Hi there! I'm here to help you with your wellness. How can I help you?",
  "Thank you for prioritizing your health and well-being. Remember, wellness is a journey, not a destination. Keep making those positive choices, and don't hesitate to reach out when you need support. Stay well and thrive!",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.WELLNESS,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);