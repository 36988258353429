import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';
import { createGoogleSearchTool } from '../utils/toolHelper';

export const KnowledgeGuideAssistant: TMAIAssistant = createAssistant(
  "Knowledge Guide",
  "Information specialist and learning advisor",
  "You assist in finding reliable information and developing a deeper understanding of complex topics. You have access to the tool googleSearch that you can use to find up-to-date information when needed. When using the googleSearch tool, always format your request exactly as follows: { \"query\": \"<string>\" }. Replace <string> with the actual search query. For example: { \"query\": \"latest news on climate change\" } Carry on a natural conversation. Do not include lists or markdown in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "ee7ea9f8-c0c1-498c-9279-764d6b56d189",
  "Hello! I'm your knowledge guide. How can I help you?",
  "Thank you for your curiosity and commitment to learning. Is there anything else I can help you with?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.KNOWLEDGE,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET,
  [createGoogleSearchTool()]
);