export const PRICING_TIERS = {
    BASIC: { name: "Basic", minutes: 20, price: 20, discount: 0 },
    STANDARD: { name: "Standard", minutes: 60, price: 45, discount: 25 },
    PREMIUM: { name: "Premium", minutes: 120, price: 80, discount: 33 },
  };
  
  export const STRIPE_PRICE_IDS = {
    BASIC: 'BASIC',
    STANDARD: 'STANDARD',
    PREMIUM: 'PREMIUM',
  };