// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ClerkProvider } from '@clerk/clerk-react';
import { initializeTheme } from './utils/theme';

// Access Clerk publishable key from environment variables
const CLERK_PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const CLERK_FRONTEND_API = process.env.REACT_APP_CLERK_FRONTEND_API;

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Clerk Publishable Key");
}

if (!CLERK_FRONTEND_API) {
  throw new Error("Missing Clerk Frontend API");
}

initializeTheme();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ClerkProvider 
      publishableKey={CLERK_PUBLISHABLE_KEY}
    >
      <App />
    </ClerkProvider>
  </React.StrictMode>
);