import React from 'react';
import { homeConfig } from '../../config/HomeConfig';

interface HowItWorksProps {
  id?: string;
}

const steps = [
  { number: 1, title: "Choose Your Plan", description: "Pick the talk time that fits your needs" },
  { number: 2, title: "Select an Advisor", description: "Wellness, career, relationships—find the right guide for you" },
  { number: 3, title: "Start Talking", description: "No awkward small talk needed - dive right into what matters to you" },
  { number: 4, title: "Gain New Perspectives", description: "Unlock fresh insights and discover new possibilities." }
];

export const HowItWorks: React.FC<HowItWorksProps> = ({ id = 'how-it-works' }) => (
  <section id={id} className={`py-20 ${homeConfig.howItWorks.backgroundColor}`}>
    <div className="container mx-auto px-4">
      <h2 className={`text-3xl font-bold text-center mb-12 ${homeConfig.howItWorks.titleColor}`}>How It Works</h2>
      <div className="flex flex-wrap justify-center">
        {steps.map((step, index) => (
          <div key={step.number} className="w-full sm:w-1/2 md:w-1/4 px-4 mb-8 text-center">
            <div className={`${homeConfig.howItWorks.stepColor} text-primary-foreground rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4 text-xl font-bold`}>
              {step.number}
            </div>
            <h3 className={`text-xl font-semibold mb-2 ${homeConfig.howItWorks.stepTitleColor}`}>{step.title}</h3>
            <p className={homeConfig.howItWorks.stepDescriptionColor}>{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);