export const homeConfig = {
  hero: {
    backgroundImage: '/assets/images/background.jpg',
    titleColor: 'text-gray-900', // or any other color class
    textColor: 'text-gray-500', // or any other color class
    primaryButtonColor: 'bg-gray-900 text-white hover:bg-gray-800',
    secondaryButtonColor: 'border border-gray-900 text-gray-900 hover:bg-gray-900 hover:text-white',
  },
  features: {
    backgroundColor: 'bg-muted',
    cardBackgroundColor: 'bg-card',
    iconColor: 'text-primary',
    title: "Features",
    titleColor: "text-primary",
    gridLayout: "grid-cols-1 md:grid-cols-3",
  },
testimonials: {
  backgroundColor: 'bg-white',
  cardBackgroundColor: 'bg-gray-100',
  titleColor: 'text-gray-900',
  quoteColor: 'text-gray-800',
  authorColor: 'text-gray-900',
  roleColor: 'text-gray-600',
},
  howItWorks: {
    backgroundColor: 'bg-white dark:bg-black',
    titleColor: 'text-gray-900 dark:text-white',
    stepColor: 'bg-primary',
    stepTitleColor: 'text-gray-800 dark:text-gray-200',
    stepDescriptionColor: 'text-gray-600 dark:text-gray-400',
  },
  advisorGallery: {
    backgroundColor: 'bg-background',
    cardBackgroundColor: 'bg-card',
  },
  pricing: {
    backgroundColor: 'bg-muted',
    cardBackgroundColor: 'bg-card',
    highlightColor: 'border-primary',
  },
  faq: {
    backgroundColor: 'bg-background',
  },
  callToAction: {
    backgroundImage: '/assets/images/background.jpg',
    titleColor: 'text-gray-900', // or any color class you prefer
    textColor: 'text-gray-700', // or any color class you prefer
    primaryButtonColor: 'bg-gray-900 text-white hover:bg-gray-800',
    secondaryButtonColor: 'border border-gray-900 text-gray-900 hover:bg-gray-900 hover:text-white',
  },
  footer: {
    backgroundColor: 'bg-gray-900',
  },
  header: {
    backgroundColor: 'bg-background',
    signInButtonClasses: 'text-primary hover:text-primary-dark transition-colors duration-200',
    signUpButtonClasses: 'bg-primary text-primary-foreground hover:bg-primary-dark px-4 py-2 rounded-full transition-colors duration-200',
  },
};

// If you're exporting the type of homeConfig, update it like this:
export type HomeConfig = typeof homeConfig;