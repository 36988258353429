import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const RelationshipAdvisorAssistant: TMAIAssistant = createAssistant(
  "Relationship Advisor",
  "Interpersonal relationship consultant",
  "You guide in enhancing communication, understanding, and connection in relationships. Carry on a natural conversation. Do not include lists or markdown in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "829ccd10-f8b3-43cd-b8a0-4aeaa81f3b30",
  "Hello! I'm here to support you with your relationships. How can I help you?",
  "Thank you for focusing on your relationships. Is there anything else I can help you with?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.RELATIONSHIP,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);