import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { homeConfig } from '../../config/HomeConfig';
//import { FaSquareXTwitter, FaFacebook, FaInstagram } from 'react-icons/fa6';
import Logo from '../Logo';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    e.preventDefault();
    if (path === '/') {
      navigate(path);
    } else if (path.startsWith('/#')) {
      if (location.pathname === '/') {
        // If we're already on the home page, just scroll to the section
        const element = document.getElementById(path.slice(2));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // If we're on a different page, navigate to home with the hash
        navigate(path);
      }
    } else {
      // For other pages like '/about', just use normal navigation
      navigate(path);
    }
  };

  const navItems = [
    { name: 'About', path: '/about' },
    { name: 'Features', path: '/#features' },
    { name: 'How It Works', path: '/#how-it-works' },
    { name: 'Pricing', path: '/#pricing' },
    { name: 'FAQ', path: '/#faq' },
  ];

  return (
    <footer className={`${homeConfig.footer.backgroundColor} py-12 text-gray-300`}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          <div className="col-span-1 md:col-span-2 lg:col-span-1">
            <Logo />
            <p className="mt-4 text-sm">
              We provide 24/7 AI voice advisors for life's challenges. We're here to listen and offer fresh perspectives anytime you need.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Links</h3>
            <ul className="space-y-2">
              {navItems.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.path}
                    className="text-sm hover:text-white transition-colors"
                    onClick={(e) => handleNavClick(e, item.path)}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Legal</h3>
            <ul className="space-y-2">
              {['Terms of Service', 'Privacy Policy'].map((item) => (
                <li key={item}>
                  <Link to={`/${item.toLowerCase().replace(/ /g, '-')}`} className="text-sm hover:text-white transition-colors">
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Contact Us</h3>
            <a href="mailto:contact@tellmeaboutit.ai" className="text-sm hover:text-white transition-colors">
              contact@tellmeaboutit.ai
            </a>
            <div className="mt-4 flex space-x-4">
              {/* <a href="#" className="text-gray-400 hover:text-white"><FaSquareXTwitter size={24} /></a> */}
              {/* <a href="#" className="text-gray-400 hover:text-white"><FaFacebook size={24} /></a> */}
              {/* <a href="#" className="text-gray-400 hover:text-white"><FaInstagram size={24} /></a> */}
            </div>
          </div>
        </div>
        <div className="pt-8 border-t border-gray-700">
          <p className="text-center text-sm text-gray-400">
            © {currentYear} TMAI. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;