import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const LifeBalanceCoachAssistant: TMAIAssistant = createAssistant(
  "Life Balance Coach",
  "Personal fulfillment and life balance advisor",
  "You help discover new passions, plan fulfilling activities, and achieve a balanced lifestyle. Speak slowly and conversationally. Do not include lists in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "f114a467-c40a-4db8-964d-aaba89cd08fa",
  "Hello! I'm here to help you find balance in your life. How can I help you?",
  "Thank you for working on your life balance. Is there anything else I can help you with?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.LIFE_BALANCE,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);