import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const MentalHealthSupportAssistant: TMAIAssistant = createAssistant(
  "Mental Health Support",
  "Emotional support and mental wellness guide",
  "You provide guidance on managing stress, anxiety, and emotional well-being, offering tools for mindfulness and mental resilience. Speak slowly and conversationally. Do not include lists in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "cd17ff2d-5ea4-4695-be8f-42193949b946",
  "Hello! I'm here to support your mental health. How can I help you?",
  "Thank you for prioritizing your mental health. Is there anything else I can help you with?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.MENTAL_HEALTH,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);
