import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SignInButton, SignUpButton } from '@clerk/clerk-react';
import { Menu, X } from 'lucide-react';
import { homeConfig } from '../../config/HomeConfig';
import Logo from '../Logo';
import DarkModeToggle from '../DarkModeToggle';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Features', path: '/#features' },
    { name: 'How It Works', path: '/#how-it-works' },
    { name: 'Pricing', path: '/#pricing' },
    { name: 'FAQ', path: '/#faq' },
    { name: 'About', path: '/about' },
  ];

  const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    e.preventDefault();
    if (path === '/') {
      navigate(path);
    } else if (path.startsWith('/#')) {
      if (location.pathname === '/') {
        // If we're already on the home page, just scroll to the section
        const element = document.getElementById(path.slice(2));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // If we're on a different page, navigate to home with the hash
        navigate(path);
      }
    } else {
      // For other pages like '/about', just use normal navigation
      navigate(path);
    }
    if (isMenuOpen) {
      toggleMenu();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <header className={`${homeConfig.header.backgroundColor} py-4 px-6 md:px-10 sticky top-0 z-50`}>
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <div className="h-8 w-auto">
            <Logo />
          </div>
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className="text-foreground hover:text-primary transition-colors duration-200"
              onClick={(e) => handleNavClick(e, item.path)}
            >
              {item.name}
            </Link>
          ))}
        </nav>

        {/* Desktop Auth Buttons and DarkModeToggle */}
        <div className="hidden md:flex items-center space-x-4">
          <DarkModeToggle />
          <SignInButton mode="modal">
            <button className={homeConfig.header.signInButtonClasses}>
              Sign In
            </button>
          </SignInButton>
          <SignUpButton mode="modal">
            <button className={homeConfig.header.signUpButtonClasses}>
              Sign Up
            </button>
          </SignUpButton>
        </div>

        {/* Mobile Menu Button */}
        <button
          ref={buttonRef}
          className="md:hidden text-foreground hover:text-primary"
          onClick={toggleMenu}
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div ref={menuRef} className="md:hidden mt-4">
          <nav className="flex flex-col space-y-4">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.path}
                className="text-foreground hover:text-primary transition-colors duration-200"
                onClick={(e) => handleNavClick(e, item.path)}
              >
                {item.name}
              </a>
            ))}
          </nav>
          <div className="mt-4 flex flex-col space-y-4">
            {/* Wrap DarkModeToggle in a container for centering */}
            <div className="flex justify-left">
              <DarkModeToggle />
            </div>
            <SignInButton mode="modal">
              <button className={`${homeConfig.header.signInButtonClasses} w-full`}>
                Sign In
              </button>
            </SignInButton>
            <SignUpButton mode="modal">
              <button className={`${homeConfig.header.signUpButtonClasses} w-full`}>
                Sign Up
              </button>
            </SignUpButton>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;