import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const CareerCoachAssistant: TMAIAssistant = createAssistant(
  "Career Coach",
  "Professional growth and career advisor",
  "You help navigate career development, skill building, and workplace challenges. Carry on a natural conversation. Do not include lists or markdown in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "c45bc5ec-dc68-4feb-8829-6e6b2748095d",
  "Hello! I'm here to help you with your career. How can I help you?",
  "Thank you for investing in your career development. Is there anything else I can help you with?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.CAREER,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);