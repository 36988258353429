import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container p-8">
      <h1 className="text-3xl font-bold mb-6">Tell Me About It Privacy Policy</h1>

      <p className="mb-4">The privacy of your data — and it is your data, not ours! — is a big deal to us. We'll only ever access your account to help you with a problem or resolve a software issue. We'll never open any uploaded files unless you explicitly ask us to. We log all access to all accounts by IP address, so we can always verify that no unauthorized access has occurred for as long as the logs are kept.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Identity & Access</h2>
      <p className="mb-4">When you sign up for Tell Me About It, we ask for your email address. This allows you to personalize your new account and enables us to send you invoices, updates, or other essential information. We will never sell your personal information to third parties, and we won't use your name or company in marketing statements without your permission.</p>
      <p className="mb-4">When you pay for Tell Me About It, we collect your credit card information to process your payment, calculate taxes, and send you invoices. Your credit card information is passed directly to our payment processor (Stripe) and does not go through our servers. We store a record of the payment transaction, including the last four digits of the credit card number, for account history, invoicing, and billing support.</p>
      <p className="mb-4">When you contact Tell Me About It with a question or request, we retain that correspondence and your email address for future reference. When you browse our website, we track that interaction for statistical purposes, such as improving conversion rates and testing new designs. We also store any information you voluntarily provide, like survey responses, for as long as it is relevant.</p>
      <p className="mb-4">The only times we will share your information are:</p>
      <ul className="list-disc list-inside mb-4">
        <li>To provide products or services you've requested, with your permission. 
          <ul className="list-disc list-inside ml-4">
          </ul>
        </li>
        <li>To investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to physical safety, violations of our Terms of Service, or as required by law.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Your Rights with Respect to Your Information</h2>
      <p className="mb-4">If you are located in Europe, you may have certain rights under the General Data Protection Regulation (GDPR) regarding your personal information. Tell Me About It recognizes and complies with GDPR rights, including:</p>
      <ul className="list-disc list-inside mb-4">
        <li><strong>Right of Access:</strong> You can request access to the personal information we hold about you and obtain details about how we share, store, secure, and process that information.</li>
        <li><strong>Right to Correction:</strong> You can request correction of your personal information.</li>
        <li><strong>Right to Erasure:</strong> You can request that your personal information be erased from our systems, subject to certain limitations under applicable law. If you request deletion of your information, this may prevent you from using Tell Me About It services and may result in the closure of your account.</li>
        <li><strong>Right to Complain:</strong> You can file a complaint regarding our handling of your personal information with the appropriate supervisory authority.</li>
        <li><strong>Right to Restrict Processing:</strong> You can request restrictions on how and why your personal information is processed.</li>
        <li><strong>Right to Object:</strong> In certain situations, you can object to how or why your personal information is processed.</li>
        <li><strong>Right to Portability:</strong> You have the right to receive your personal information and transmit it to another party.</li>
        <li><strong>Right to not be subject to Automated Decision-Making:</strong> You have the right to object to decisions made solely based on automated processing that may have a legal or similarly significant effect on you, except in cases where such decisions are necessary for the performance of a contract, allowed by law, or based on your explicit consent.</li>
      </ul>
      <p className="mb-4">Many of these rights can be exercised by signing in and updating your account information directly. If you have questions about exercising these rights or need assistance, please contact us at <a href="mailto:contact@tellmeaboutit.ai" className="text-blue-600 hover:underline">contact@tellmeaboutit.ai</a>.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Law Enforcement</h2>
      <p className="mb-4">Tell Me About It will not hand over your data to law enforcement unless required by a court order. We will reject requests for data from local and federal law enforcement without a court order. Unless legally prohibited, we will inform you if such requests are made.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Security & Encryption</h2>
      <p className="mb-4">All data is encrypted using SSL/TLS when transmitted from our servers to your browser. Data is encrypted both in transit and at rest to ensure its security.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Deleted Data</h2>
      <p className="mb-4">When you cancel your account, we will ensure that all your data is deleted from our servers within 30 days. Any data you delete from your account while it remains active will also be purged within 30 days (until then, it will be available in the trash can).</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Location of Site and Data</h2>
      <p className="mb-4">This site is operated in the United States, within the Amazon Web Services us-east-1 region. If you are located in the European Union or elsewhere outside the United States, please be aware that any information you provide to us will be transferred to the United States. By using our site, participating in our services, and providing us with your information, you consent to this transfer.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Changes & Questions</h2>
      <p className="mb-4">Tell Me About It may update this policy occasionally — we'll notify you about significant changes by emailing the account owner or by placing a prominent notice on our site. You can access, change, or delete your personal information at any time by contacting us.</p>
      <p className="mb-4">Questions about this privacy policy? Please get in touch, and we'll be happy to answer them!</p>

    </div>
  );
};

export default PrivacyPolicy;
