import React from 'react';
import { SignUpButton } from '@clerk/clerk-react';
import { homeConfig } from '../../config/HomeConfig';
import { ArrowRight } from 'lucide-react';

export const CallToAction: React.FC = () => {
  return (
    <section className={`bg-primary text-primary-foreground py-40 bg-cover bg-center`} style={{backgroundImage: `url(${homeConfig.callToAction.backgroundImage})`}}>
      <div className="container mx-auto px-4 text-center">
        <h2 className={`text-3xl sm:text-4xl md:text-5xl font-bold mb-6 ${homeConfig.callToAction.titleColor}`}>
          Ready to Be Heard?
        </h2>
        <p className={`text-lg sm:text-xl mb-8 sm:mb-10 max-w-2xl mx-auto ${homeConfig.callToAction.textColor}`}>
          Everyone needs someone to talk to. Tell Me About It is here 24/7, ready to offer fresh perspectives on whatever life throws your way.
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
          <SignUpButton mode="modal">
            <button className={`${homeConfig.callToAction.primaryButtonColor} font-bold py-3 px-8 rounded-full transition duration-300 flex items-center justify-center`}>
              Start Your Conversation
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </SignUpButton>
          <a 
            href="#pricing" 
            className={`${homeConfig.callToAction.secondaryButtonColor} font-bold py-3 px-8 rounded-full transition duration-300 flex items-center justify-center`}
          >
            View Pricing
            <ArrowRight className="ml-2 h-5 w-5" />
          </a>
        </div>
      </div>
    </section>
  );
};