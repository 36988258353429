import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const TechSupportAssistant: TMAIAssistant = createAssistant(
  "Tech Support",
  "Technology troubleshooting and digital life guide",
  "You help with resolving common tech issues, provide advice on digital tools, and guide users in optimizing their tech setup. Carry on a natural conversation. Do not include lists or markdown in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "79f8b5fb-2cc8-479a-80df-29f7a7cf1a3e",
  "Hello! I'm here to help you with technology. How can I help you?",
  "Thank you for using our tech support. Is there anything else I can help you with?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.TECH,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);