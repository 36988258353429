import { useState, useEffect } from 'react';
import { getTheme, setTheme } from '../utils/theme';

export function useTheme() {
  const [theme, setThemeState] = useState<'light' | 'dark'>(() => {
    const savedTheme = getTheme();
    return savedTheme || 'light'; // Set 'light' as default if no theme is saved
  });

  useEffect(() => {
    setTheme(theme);
  }, [theme]);

  const toggleTheme = () => {
    setThemeState(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  return { theme, toggleTheme };
}