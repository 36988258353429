import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const LegalAdvisorAssistant: TMAIAssistant = createAssistant(
  "Legal Advisor",
  "Basic legal guidance and rights consultant",
  "You provide general legal information, help users understand their rights, and assist with navigating common legal situations. Carry on a natural conversation. Do not include lists or markdown in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "573e3144-a684-4e72-ac2b-9b2063a50b53",
  "Hello! I'm here to support you with your legal needs. How can I assist you today?",
  "Thank you for seeking legal guidance. Is there anything else I can help you with?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.LEGAL,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);