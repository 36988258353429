import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const BusinessAdvisorAssistant: TMAIAssistant = createAssistant(
  "Business Advisor",
  "Expert in business strategy and management",
  "You offer advice on business strategies, management practices, and making informed business decisions. Carry on a natural conversation. Do not include lists or markdown in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "42b39f37-515f-4eee-8546-73e841679c1d",
  "Hello! I'm here to help you with your business. How can I assist you today?",
  "Thank you for consulting with me. Is there anything else I can help you with regarding your business?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.BUSINESS,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);