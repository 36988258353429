// src/components/AssistantCard.tsx

import React from 'react';
import { CallButton } from './CallButton';
import { TMAIAssistant } from '../types/AssistantTypes';

interface AssistantWithUI extends TMAIAssistant {
  ui: {
    role: string;
    description: string;
    avatar: string;
  };
}

interface AssistantCardProps {
  assistant: AssistantWithUI;
  isActive: boolean;
  isLoading: boolean;
  onCallInitiate: (assistant: AssistantWithUI) => void;
}

export const AssistantCard: React.FC<AssistantCardProps> = ({
  assistant,
  isActive,
  isLoading,
  onCallInitiate,
}) => (
  <div className="flex flex-col rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 shadow-lg p-6 max-w-sm">
    <div className="flex items-center space-x-4 mb-4">
      <img
        src={assistant.ui.avatar}
        alt={`${assistant.name} avatar`}
        className="w-16 h-16 object-cover rounded-full shadow-md"
      />
      <div className="flex flex-col">
        <h2 className="text-xl font-bold text-gray-900 dark:text-white">{assistant.name}</h2>
        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">{assistant.ui.role}</p>
      </div>
    </div>
    <div className="flex-1">
      <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">{assistant.ui.description}</p>
    </div>
    <div className="mt-4">
      <CallButton
        assistantName={assistant.name}
        isActive={isActive}
        isLoading={isLoading}
        onClick={() => onCallInitiate(assistant)}
      />
    </div>
  </div>
);