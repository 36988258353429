import React, { useState, useEffect } from 'react';

interface PreferencesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (preferences: string) => void;
}

const PreferencesModal: React.FC<PreferencesModalProps> = ({ isOpen, onClose, onSave }) => {
  const [preferences, setPreferences] = useState('');

  useEffect(() => {
    const storedPreferences = localStorage.getItem('userPreferences');
    if (storedPreferences) {
      setPreferences(storedPreferences);
    }
  }, []);

  const handleSave = () => {
    localStorage.setItem('userPreferences', preferences);
    onSave(preferences);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
      onClick={onClose}
    >
      <div
        className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-3xl"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Personalize Your Experience</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          Provide information about yourself to help our AI advisors offer more tailored guidance and support.
        </p>
        <textarea
          className="w-full p-4 border border-gray-300 dark:border-gray-600 rounded-lg mb-6 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
          rows={10}
          placeholder="Please share your goals, interests, and any specific areas where you'd like assistance. Your input helps us customize our advice to better suit your needs."
          value={preferences}
          onChange={(e) => setPreferences(e.target.value)}
        />
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-6 py-3 bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors font-semibold"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreferencesModal;