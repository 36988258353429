import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NonAuthLayout from './components/layout/NonAuthLayout';
import AuthLayout from './components/layout/AuthLayout';
import NoHeaderLayout from './components/layout/NoHeaderLayout';
import Home from './pages/Home';
import Dashboard from './pages/auth/Dashboard';
import About from './pages/About';
import Profile from './pages/auth/Profile';
import Login from './pages/Login';
import Register from './pages/Register';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component
import { useAuth } from '@clerk/clerk-react';
import Loader from './components/Loader';

function App() {
  const { isLoaded, userId } = useAuth();

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Public routes accessible to all users */}
        <Route element={<NonAuthLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
        </Route>

        {/* Authentication routes */}
        <Route element={<NoHeaderLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>

        {/* Protected routes */}
        {userId ? (
          <Route element={<AuthLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            {/* Add other authenticated routes here */}
          </Route>
        ) : null}

        <Route path="*" element={<Navigate to={userId ? "/dashboard" : "/"} replace />} />
      </Routes>
    </Router>
  );
}

export default App;
