import React from 'react';
import { SignedIn, SignedOut, SignUpButton } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import Footer from '../components/landing/Footer';

const About: React.FC = () => {
  return (
    <div className="bg-background text-foreground min-h-screen flex flex-col">
      <SignedIn>
        <Navigate to="/dashboard" replace />
      </SignedIn>
      <SignedOut>
        <main className="flex-grow container mx-auto px-4 py-12">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-5xl font-bold mb-8 text-left">About Us</h1>
            <h2 className="text-3xl font-semibold mb-6 text-left">Someone to Talk to, Anytime You Need</h2>
            <p className="mb-8 text-lg">
              Tell Me About It offers AI voice advisors that are available 24/7, ready to listen and offer fresh perspectives on whatever life throws your way. We believe that everyone can benefit from having a supportive conversation partner, especially during those times when friends or family might not be available.
            </p>

            <h2 className="text-2xl font-semibold mb-4">What is Tell Me About It?</h2>
            <p className="mb-6 text-lg">
              Tell Me About It provides AI-powered conversation partners that you can talk to about anything on your mind. Our service is designed to be a complement to, not a replacement for, human connections. We offer a space where you can explore your thoughts, gain new insights, and work through challenges at any time of day or night.
            </p>
            <p className="mb-4 text-lg">Our AI advisors cover a range of areas, including:</p>
            <ul className="list-disc list-inside mb-8 text-lg">
              <li>Wellness and mental health support</li>
              <li>Career and professional development</li>
              <li>Relationships and interpersonal dynamics</li>
              <li>Life balance and personal growth</li>
              <li>Knowledge exploration and learning</li>
              <li>Creative thinking and problem-solving</li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">Our Commitment</h2>
            <p className="mb-6 text-lg">
              At Tell Me About It, we're committed to creating a helpful, ethical AI service. We're continuously working to improve our AI advisors, making them more helpful and insightful. As we grow, we'll be guided by feedback from our users and advancements in AI technology.
            </p>
            <p className="mb-8 text-lg">
              We believe in being transparent about what our service is - and what it isn't. Our AI advisors are not a substitute for professional medical, legal, or financial advice. For serious issues, we always recommend seeking help from qualified human professionals.
            </p>

            <div className="bg-primary text-primary-foreground rounded-lg p-8 text-center">
              <h2 className="text-2xl font-semibold mb-4">Join Us</h2>
              <p className="mb-6 text-lg">
                We're excited to grow and learn alongside our users. If you're curious about exploring AI-assisted conversations, we invite you to give Tell Me About It a try.
              </p>
              <SignUpButton mode="modal">
                <button className="bg-background text-foreground px-6 py-3 rounded-full font-semibold hover:bg-opacity-90 transition-colors">
                  Get Started
                </button>
              </SignUpButton>
            </div>
          </div>
        </main>
        <Footer />
      </SignedOut>
    </div>
  );
};

export default About;