export const createGoogleSearchTool = () => {
  return {
    type: "function",
    messages: [
      {
        type: "request-start",
        content: "One moment please..."
      },
      {
        type: "request-complete",
        content: "Here's what I found:"
      },
      {
        type: "request-failed",
        content: "I'm sorry, I couldn't retrieve that information right now."
      }
    ],
    function: {
      name: "googleSearch",
      description: "Search the web using Google",
      parameters: {
        type: "object",
        properties: {
          query: {
            type: "string",
            description: "The search query"
          }
        },
        required: ["query"]
      }
    },
    async: false,
    server: {
      timeoutSeconds: 20,
      url: `${process.env.REACT_APP_API_BASE_URL}/tools/googleSearch`,
      secret: process.env.REACT_APP_VAPI_WEBHOOK_SECRET
    }
  };
};