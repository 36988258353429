// src/factory/AssistantFactory.ts

import { metaPromptTemplate } from '../config/metaPrompt';
import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';

export function createAssistant(
  name: string,
  role: string,
  description: string,
  transcriberProvider: string,
  transcriberModel: string,
  transcriberLanguage: string,
  modelProvider: string,
  modelName: string,
  voiceProvider: string,
  voiceId: string,
  firstMessage: string,
  endCallMessage: string,
  endCallPhrases: string[],
  category: AssistantCategory,
  serverUrl?: string,
  serverUrlSecret?: string,
  tools?: any[]
): TMAIAssistant {
  const metaPrompt = metaPromptTemplate(name, role, description);

  return {
    transcriber: {
      provider: transcriberProvider,
      model: transcriberModel,
      language: transcriberLanguage,
    },
    model: {
      provider: modelProvider,
      model: modelName,
      messages: [
        {
          role: "system",
          content: metaPrompt,
        },
      ],
      tools: tools, // Add tools here
    },
    voice: {
      provider: voiceProvider,
      voiceId: voiceId,
    },
    name,
    firstMessage,
    endCallMessage,
    endCallPhrases,
    category,
    serverUrl,
    serverUrlSecret,
    tools,
  };
}