import React from 'react';
import { SignUpButton } from '@clerk/clerk-react';
import { homeConfig } from '../../config/HomeConfig';

export const Hero: React.FC = () => (
  <section className={`bg-primary text-primary-foreground py-20 md:py-40 bg-cover bg-center`} style={{backgroundImage: `url(${homeConfig.hero.backgroundImage})`}}>
    <div className="container mx-auto text-center px-4">
      <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${homeConfig.hero.titleColor}`}>
        Someone to Talk to,<br />
        Anytime You Need
      </h1>
      <p className={`text-xl mb-8 max-w-2xl mx-auto ${homeConfig.hero.textColor}`}>
        Voice AI advisors available 24/7, ready to listen and offer fresh perspectives on whatever life throws your way.
      </p>
      <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
        <SignUpButton mode="modal">
          <button className={`${homeConfig.hero.primaryButtonColor} font-bold py-3 px-8 rounded-full transition duration-300`}>
            Start Your Conversation
          </button>
        </SignUpButton>
        <a href="#how-it-works" className={`${homeConfig.hero.secondaryButtonColor} font-bold py-3 px-8 rounded-full transition duration-300`}>
          See How It Works
        </a>
      </div>
    </div>
  </section>
);