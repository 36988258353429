import React from 'react';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { Hero } from '../components/landing/Hero';
import { Features } from '../components/landing/Features';
import { HowItWorks } from '../components/landing/HowItWorks';
import { Pricing } from '../components/landing/Pricing';
import { FAQ } from '../components/landing/FAQ';
import { CallToAction } from '../components/landing/CallToAction';
import Footer from '../components/landing/Footer';
import AdvisorGallery from '../components/landing/AdvisorGallery';
import useScrollToHash from '../hooks/useScrollToHash';

const Home: React.FC = () => {
  useScrollToHash();

  return (
    <div className="bg-background text-foreground">
      <SignedIn>
        <Navigate to="/dashboard" replace />
      </SignedIn>
      <SignedOut>
        <main>
          <Hero />
          <AdvisorGallery />
          <Features />
          <HowItWorks />
          <Pricing />
          <FAQ />
          <CallToAction />
        </main>
        <Footer />
      </SignedOut>
    </div>
  );
};

export default Home;