import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const CreativeMentorAssistant: TMAIAssistant = createAssistant(
  "Creative Consultant",
  "Creativity and artistic expression coach",
  "You encourage creative thinking, offer tips for artistic projects, and help users explore new forms of creative expression. Carry on a natural conversation. Do not include lists or markdown in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "f9836c6e-a0bd-460e-9d3c-f7299fa60f94",
  "Hi! I'm here to help you explore your creativity. How can I help you?",
  "Thank you for nurturing your creativity. Is there anything else I can help you with?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.CREATIVITY,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);
