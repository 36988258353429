import React from 'react';
import { Button } from './ui/Button'; // Update this import path if necessary
import { FiPhone, FiPhoneIncoming } from 'react-icons/fi'; // Import phone icons

interface CallButtonProps {
  assistantName: string;
  isActive: boolean;
  isLoading: boolean;
  onClick: () => void;
}

export const CallButton: React.FC<CallButtonProps> = ({
  assistantName,
  isActive,
  isLoading,
  onClick,
}) => (
  <Button
    className={`w-full flex items-center justify-center ${
      isActive
        ? 'bg-red-500 hover:bg-red-600 text-white'
        : 'bg-blue-500 hover:bg-blue-600 text-white'
    } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
    onClick={onClick}
    disabled={isLoading}
  >
    {isLoading ? (
      <span className="mr-2">⏳</span>
    ) : isActive ? (
      <FiPhoneIncoming className="mr-2" />
    ) : (
      <FiPhone className="mr-2" />
    )}
    {isLoading ? 'Connecting...' : isActive ? 'End Call' : `Call ${assistantName}`}
  </Button>
);
