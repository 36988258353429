import React from 'react';

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container p-8">
      <h1 className="text-3xl font-bold mb-6">Tell Me About It Terms of Service</h1>
      
      <p>By using the Tell Me About It ("Service"), you are agreeing to be bound by the following terms and conditions ("Terms of Service").</p>
      <p>TMAI, LLC ("Company") reserves the right to update and change these Terms of Service without notice.</p>
      <p>Violation of any of the terms below may result in the termination of your account.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Account Terms</h2>
      <ul className="list-disc list-inside">
        <li>You are responsible for maintaining the security of your account and password. The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
        <li>You are responsible for all activity that occurs under your account.</li>
        <li>You may not use the Service for any illegal purpose or to violate any laws in your jurisdiction (including but not limited to copyright laws).</li>
        <li>You must provide a valid email address and any other information requested to complete the signup process.</li>
        <li>Your login may only be used by one person – a single login shared by multiple people is not permitted.</li>
        <li>You must be a human. Accounts registered by "bots" or other automated methods are not permitted.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Payment, Refunds, and Plan Terms</h2>
      <ul className="list-disc list-inside">
        <li>The Service offers three prepaid plans: Basic ($20 for 20 minutes), Standard ($45 for 60 minutes), and Premium ($80 for 120 minutes). Access to AI advisors is contingent upon selecting and paying for one of these plans.</li>
        <li><strong>Billing:</strong> All plans are prepaid and billed upfront. Minutes are deducted from your account balance as you use the service.</li>
        <li><strong>Non-Expiring Minutes:</strong> Purchased minutes do not expire and remain available for use until consumed.</li>
        <li><strong>Plan Changes:</strong> You may purchase additional minutes or upgrade to a higher-tier plan at any time. New minutes will be added to your existing balance.</li>
        <li><strong>Refunds:</strong> As minutes do not expire, we do not offer refunds for unused minutes. However, if you experience technical issues that impact your ability to use the service, please contact our support team for assistance.</li>
        <li><strong>Feature Access:</strong> All plans provide full access to all AI advisors. There are no feature restrictions based on plan type.</li>
        <li><strong>Taxes:</strong> All fees are exclusive of any applicable taxes, levies, or duties imposed by taxing authorities. You are responsible for paying any such applicable taxes. Where required, the Company will collect taxes on behalf of taxing authorities and remit them accordingly.</li>
        <li><strong>Price Changes:</strong> We reserve the right to change our prices at any time. Any price changes will be communicated in advance and will only apply to future purchases.</li>
        <li><strong>Account Suspension:</strong> If your account has no available minutes, you will not be able to access the AI advisors until you purchase additional minutes.</li>
      </ul>

      <p className="mt-4">By using our service, you agree to these payment terms. For any questions or concerns regarding billing, please contact our support team.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Cancellation and Termination</h2>
      <ul className="list-disc list-inside">
        <li><strong>Account Management:</strong> You can manage your account, including purchasing additional minutes or canceling your account, at any time by logging into your account settings or contacting our support team at contact@tellmeaboutit.ai.</li>
        <li><strong>Cancellation:</strong> As our service operates on a prepaid, non-subscription model, there is no need to cancel a recurring subscription. Your account will remain active until you use all your purchased minutes or choose to close your account.</li>
        <li><strong>Account Closure:</strong> If you wish to close your account, you can do so at any time through the Account Settings page or by contacting our support team. Any unused minutes at the time of account closure will be forfeited.</li>
        <li><strong>Refunds:</strong> As stated in our Payment Terms, we do not offer refunds for unused minutes. However, if you experience technical issues that impact your ability to use the service, please contact our support team for assistance.</li>
        <li><strong>Company's Right to Terminate:</strong> The Company reserves the right, at its sole discretion, to suspend or terminate your account and refuse any and all current or future use of the Service at any time for any reason. This termination may result in the deactivation or deletion of your account, or your access to your account, and the forfeiture of any unused minutes. The Company reserves the right to refuse service to anyone for any reason at any time.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Modifications to the Service and Prices</h2>
      <ul className="list-disc list-inside">
        <li>The Company reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, any part of the Service with or without notice.</li>
        <li>Prices of all Services are subject to change upon 30 days' notice from us. Such notice may be provided at any time by posting the changes to the Company website or the Service itself.</li>
        <li>The Company shall not be liable to you or to any third party for any modification, price change, suspension, or discontinuance of the Service.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Copyright and Content Ownership</h2>
      <ul className="list-disc list-inside">
        <li>We claim no intellectual property rights over the material you provide to the Service. All materials uploaded remain yours.</li>
        <li>The look and feel of the Service is copyright© to the Company. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written permission from the Company.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">General Conditions</h2>
      <ul className="list-disc list-inside">
        <li>Your use of the Service is at your sole risk. The service is provided on an "as is" and "as available" basis.</li>
        <li>Technical support is only provided via email.</li>
        <li>You understand that the Company uses third-party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.</li>
        <li>You must not modify, adapt, or hack the Service.</li>
        <li>You must not modify another website so as to falsely imply that it is associated with the Service or the Company.</li>
        <li>You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by the Company.</li>
        <li>We may, but have no obligation to, remove content and accounts that we determine in our sole discretion are unlawful or violate any party's intellectual property or these Terms of Service.</li>
        <li>Verbal, physical, written, or other abuse (including threats of abuse or retribution) of any Service customer, Company employee, or officer will result in immediate account termination.</li>
        <li>You understand that the technical processing and transmission of the Service, including your content, may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.</li>
        <li>We reserve the right to temporarily disable your account if your usage significantly exceeds the average usage of other Service customers. Of course, we'll reach out to the account owner before taking any action except in rare cases where the level of use may negatively impact the performance of the Service for other customers.</li>
        <li>The Company does not warrant that (i) the service will meet your specific requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations, and (v) any errors in the Service will be corrected.</li>
        <li>You expressly understand and agree that the Company shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to the service.</li>
        <li>The failure of the Company to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitute the entire agreement between you and the Company and govern your use of the Service, superseding any prior agreements between you and the Company (including, but not limited to, any prior versions of the Terms of Service).</li>
      </ul>

      <p className="mt-6">Questions about the Terms of Service should be sent to <a href="mailto:contact@tellmeaboutit.ai" className="text-blue-600 hover:underline">contact@tellmeaboutit.ai</a>.</p>

      <p className="mt-6">Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service. Continued use of the Service after any such changes shall constitute your consent to such changes.</p>

    </div>
  );
};

export default TermsOfService;