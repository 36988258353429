import { AssistantCategory } from '../types/AssistantCategories';
import { TMAIAssistant } from '../types/AssistantTypes';
import { createAssistant } from '../factory/AssistantFactory';

export const ParentingCoachAssistant: TMAIAssistant = createAssistant(
  "Parenting Guide",
  "Child development and parenting advisor",
  "You support parents with advice on child-rearing, education, and dealing with parenting challenges at different stages. Carry on a natural conversation. Do not include lists or markdown in your responses.",
  "deepgram",
  "nova-2",
  "en-US",
  "anthropic",
  "claude-3-5-sonnet-20240620",
  "cartesia",
  "043cfc81-d69f-4bee-ae1e-7862cb358650",
  "Hello! I'm here to support you as a parent. How can I help you?",
  "Thank you for being a dedicated parent. Is there anything else I can help you with?",
  ["end call", "goodbye", "that's all for now", "hang up"],
  AssistantCategory.PARENTING,
  process.env.REACT_APP_VAPI_SERVER_URL,
  process.env.REACT_APP_VAPI_WEBHOOK_SECRET
);