export enum AssistantCategory {
  CAREER = 'Career',
  CREATIVITY = 'Creativity',
  BUSINESS = 'Business',
  FINANCE = 'Finance',
  KNOWLEDGE = 'Knowledge',
  LEGAL = 'Legal',
  LIFE_BALANCE = 'Life Balance',
  MENTAL_HEALTH = 'Mental Health',
  PARENTING = 'Parenting',
  RELATIONSHIP = 'Relationship',
  TECH = 'Tech',
  WELLNESS = 'Wellness',
}
