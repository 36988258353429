import React, { useState } from 'react';
import { PRICING_TIERS, STRIPE_PRICE_IDS } from '../config/stripe';
import { useStripe } from '../hooks/useStripe';

interface PurchaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialMessage?: string;
}

type PricingTier = keyof typeof PRICING_TIERS;

const PurchaseModal: React.FC<PurchaseModalProps> = ({ isOpen, onClose, initialMessage }) => {
  const { createCheckoutSession, loading, error } = useStripe();
  const [selectedTier, setSelectedTier] = useState<PricingTier>('BASIC');

  if (!isOpen) return null;

  const handlePurchase = () => {
    createCheckoutSession(STRIPE_PRICE_IDS[selectedTier]);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75" onClick={onClose}></div>
      <div className="relative w-full max-w-md mx-auto z-10 px-4"> {/* Added px-4 here */}
        <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl p-6">
          <h2 className="text-2xl font-bold text-center mb-4 text-gray-900 dark:text-white">Buy Advisor Minutes</h2>
          
          {initialMessage && (
            <p className="text-sm text-red-600 dark:text-red-400 mb-4 text-center">{initialMessage}</p>
          )}
          
          <div className="mb-6">
            <select
              value={selectedTier}
              onChange={(e) => setSelectedTier(e.target.value as PricingTier)}
              className="w-full p-2 border rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600 focus:ring-primary focus:border-primary"
            >
              {Object.entries(PRICING_TIERS).map(([tier, { name, minutes, price }]) => (
                <option key={tier} value={tier}>
                  {minutes} minutes for ${price}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-between items-center mb-4">
            <span className="text-lg text-gray-700 dark:text-gray-300">Total:</span>
            <span className="text-2xl font-bold text-gray-900 dark:text-white">${PRICING_TIERS[selectedTier].price}</span>
          </div>
          <button
            onClick={handlePurchase}
            disabled={loading}
            className="w-full py-2 rounded-lg transition duration-300 bg-blue-500 hover:bg-blue-600 text-white font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? 'Processing...' : 'Buy Minutes'}
          </button>
          {error && <p className="mt-4 text-sm text-red-600 dark:text-red-400 text-center">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default PurchaseModal;