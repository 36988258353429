import React from 'react';

const Logo: React.FC = () => (
  <div className="flex items-center">
    <img 
      src="/assets/logo-short-white.png" 
      alt="TMAI Logo" 
      className="h-8 w-auto" // Adjust the height as needed
    />
  </div>
);

export default Logo;