export const AssistantUIConfig = {
  WellnessAssistant: {
    role: "Wellness Guide",
    description:
      "Discuss personalized approaches to nutrition, fitness, and stress management to enhance your overall well-being.",
    avatar: "/assets/images/wellness_guide_advisor.jpg",
  },
  FinancialAdvisorAssistant: {
    role: "Financial Advisor",
    description:
      "Explore strategies for budgeting, investing, and managing risk to build long-term financial stability and growth.",
    avatar: "/assets/images/financial_advisor.jpg",
  },
  CareerCoachAssistant: {
    role: "Career Coach",
    description:
      "Navigate your professional journey with insights on skill development, networking, and workplace dynamics.",
    avatar: "/assets/images/career_coach.jpg",
  },
  RelationshipAdvisorAssistant: {
    role: "Relationship Advisor",
    description:
      "Improve personal and professional relationships through effective communication and conflict resolution techniques.",
    avatar: "/assets/images/relationship_advisor.jpg",
  },
  KnowledgeGuideAssistant: {
    role: "Knowledge Guide",
    description:
      "Engage in thought-provoking discussions to enhance critical thinking and explore complex topics across various fields.",
    avatar: "/assets/images/knowledge_guide.jpg",
  },
  LifeBalanceCoachAssistant: {
    role: "Life Balance Coach",
    description:
      "Discover ways to harmonize your personal and professional life, leading to greater satisfaction and purpose.",
    avatar: "/assets/images/life_balance_coach.jpg",
  },
  MentalHealthSupportAssistant: {
    role: "Mental Health Support",
    description:
      "Explore strategies for managing stress, anxiety, and emotional challenges to foster long-term mental well-being.",
    avatar: "/assets/images/mental_health_support.jpg",
  },
  BusinessAdvisorAssistant: {
    role: "Business Advisor",
    description:
      "Get expert guidance on business strategies, management practices, and making informed decisions to grow your business.",
    avatar: "/assets/images/business_advisor.jpg",
  },
  ParentingCoachAssistant: {
    role: "Parenting Guide",
    description:
      "Discuss child development, effective parenting techniques, and strategies for nurturing healthy family relationships.",
    avatar: "/assets/images/parenting_coach.jpg",
  },
  LegalAdvisorAssistant: {
    role: "Legal Guide",
    description:
      "Understand basic legal concepts, individual rights, and navigate common legal scenarios with greater confidence.",
    avatar: "/assets/images/legal_advisor.jpg",
  },
  TechSupportAssistant: {
    role: "Tech Support",
    description:
      "Get help with tech issues, discover useful digital tools, and learn strategies for efficient technology use in daily life.",
    avatar: "/assets/images/tech_support.jpg",
  },
  CreativeMentorAssistant: {
    role: "Creative Mentor",
    description:
      "Spark your creativity, explore various forms of artistic expression, and get guidance on your creative projects.",
    avatar: "/assets/images/creative_mentor.jpg",
  },
};