import React from 'react';
import { SignIn } from '@clerk/clerk-react';

const Login: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <img src="/assets/logo-short-white.png" alt="Logo" className="mb-8 w-24" />
      <SignIn 
        path="/login" 
        signUpUrl="/register"  // No `routing="path"` here
      />
    </div>
  );
};

export default Login;
