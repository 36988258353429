import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SignInButton, SignUpButton, UserButton, SignedIn, SignedOut } from '@clerk/clerk-react';
import { FiMenu, FiX } from 'react-icons/fi';
import Logo from '../Logo';
import BuyMinutesButton from '../BuyMinutesButton';
import { useUserData } from '../../hooks/useUserData';
import DarkModeToggle from '../DarkModeToggle';

interface DashboardHeaderProps {
  menu: { label: string; href: string }[];
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ menu }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { userData, loading: userDataLoading } = useUserData();

  const formatMinutes = (minutes: number) => {
    return Math.round(minutes);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const renderMinutesDisplay = () => {
    if (userDataLoading) return <span className="text-gray-600 dark:text-gray-400">Loading...</span>;
    
    const minutes = userData?.minutesRemaining ?? 0;
    return (
      <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
        {formatMinutes(minutes)} min remaining
      </span>
    );
  };

  return (
    <header className="bg-background dark:bg-gray-800 shadow-md">
      <div className="container mx-auto px-4 py-4 flex items-center justify-between">
        <Link to="/" className="flex items-center">
          <div className="h-8 w-auto">
            <Logo />
          </div>
        </Link>

        {/* Desktop menu */}
        <div className="hidden lg:flex items-center space-x-6">
          <nav className="flex space-x-6">
            {menu.map((item) => (
              <Link key={item.href} to={item.href} className="text-gray-600 hover:text-primary">
                {item.label}
              </Link>
            ))}
          </nav>
          {renderMinutesDisplay()}
          <BuyMinutesButton />
          <DarkModeToggle />
          <SignedIn>
            <UserButton afterSignOutUrl="/" />
          </SignedIn>
          <SignedOut>
            <SignInButton mode="modal">
              <button className="text-primary hover:text-primary-dark">Sign In</button>
            </SignInButton>
            <SignUpButton mode="modal">
              <button className="bg-primary text-white hover:bg-primary-dark px-4 py-2 rounded-full">
                Sign Up
              </button>
            </SignUpButton>
          </SignedOut>
        </div>

        {/* Mobile menu button */}
        <div className="lg:hidden flex items-center">
          <SignedIn>
            <UserButton afterSignOutUrl="/" />
          </SignedIn>
          <button
            onClick={toggleMobileMenu}
            className="ml-4 text-gray-600 hover:text-primary focus:outline-none"
          >
            {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div ref={menuRef} className="lg:hidden bg-background dark:bg-gray-800 shadow-md">
          <nav className="flex flex-col px-4 py-2 space-y-2">
            {menu.map((item) => (
              <Link
                key={item.href}
                to={item.href}
                className="text-gray-600 hover:text-primary py-2"
                onClick={toggleMobileMenu}
              >
                {item.label}
              </Link>
            ))}
            <div className="flex items-center justify-between py-2">
              {renderMinutesDisplay()}
              <BuyMinutesButton />
            </div>
            <div className="flex items-center justify-between py-2">
              <DarkModeToggle />
              <SignedOut>
                <div className="flex space-x-2">
                  <SignInButton mode="modal">
                    <button className="text-primary hover:text-primary-dark">Sign In</button>
                  </SignInButton>
                  <SignUpButton mode="modal">
                    <button className="bg-primary text-white hover:bg-primary-dark px-4 py-2 rounded-full">
                      Sign Up
                    </button>
                  </SignUpButton>
                </div>
              </SignedOut>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

export default DashboardHeader;