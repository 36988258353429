import React, { useState, useRef } from 'react';
import { homeConfig } from '../../config/HomeConfig';
import { ChevronLeft, ChevronRight, Play, Pause } from 'lucide-react';

type Advisor = {
  name: string;
  description: string;
  avatar: string;
  audioSample: string;
};

// Sample data for predefined advisors
const advisors: Advisor[] = [
  {
    name: "Wellness Guide",
    description: "Explore personalized approaches to nutrition, fitness, and stress management. Let's discuss strategies to enhance your overall physical and mental well-being.",
    avatar: "/assets/images/wellness_guide_advisor.jpg",
    audioSample: "/assets/audio/wellness_guide_sample.wav",
  },
  {
    name: "Mental Health Support",
    description: "Discuss strategies for managing stress, anxiety, and emotional challenges. Let's explore ways to foster long-term mental well-being and resilience.",
    avatar: "/assets/images/mental_health_support.jpg",
    audioSample: "/assets/audio/mental_health_support_assistant_sample.wav",
  },
  {
    name: "Relationship Advisor",
    description: "Let's explore strategies for building stronger, more fulfilling relationships with your loved ones and peers.",
    avatar: "/assets/images/relationship_advisor.jpg",
    audioSample: "/assets/audio/relationship_advisor_sample.wav",
  },
  {
    name: "Life Balance Coach",
    description: "Explore strategies for aligning your personal and professional life. We can discuss time management, stress reduction, and finding purpose in daily activities.",
    avatar: "/assets/images/life_balance_coach.jpg",
    audioSample: "/assets/audio/life_balance_coach_sample.wav",
  },
  {
    name: "Knowledge Guide",
    description: "Engage in conversations about complex topics and develop critical thinking skills. Let's explore subjects that pique your curiosity and expand your understanding.",
    avatar: "/assets/images/knowledge_guide.jpg",
    audioSample: "/assets/audio/knowledge_guide_sample.wav",
  },
  {
    name: "Career Coach",
    description: "Discuss your professional journey, from job satisfaction to career growth strategies. Let's explore ways to enhance your skills and navigate workplace dynamics.",
    avatar: "/assets/images/career_coach.jpg",
    audioSample: "/assets/audio/career_coach_sample.wav",
  },
  {
    name: "Financial Advisor",
    description: "Talk through your financial goals and concerns. We can discuss budgeting strategies, investment basics, and approaches to long-term financial planning.",
    avatar: "/assets/images/financial_advisor.jpg",
    audioSample: "/assets/audio/financial_advisor_sample.wav",
  },
  {
    name: "Parenting Guide",
    description: "Discuss child development, effective parenting techniques, and strategies for fostering healthy family relationships. Let's talk about the joys and challenges of parenthood.",
    avatar: "/assets/images/parenting_coach.jpg",
    audioSample: "/assets/audio/parenting_guide_sample.wav",
  },
  {
    name: "Creative Consultant",
    description: "Explore your artistic side and overcome creative blocks. We can discuss project ideas, inspiration sources, and various forms of creative expression.",
    avatar: "/assets/images/creative_mentor.jpg",
    audioSample: "/assets/audio/creative_consultant_sample.wav",
  },
  {
    name: "Business Advisor",
    description: "Get expert guidance on business strategies, management practices, and making informed decisions to grow your business.",
    avatar: "/assets/images/business_advisor.jpg",
    audioSample: "/assets/audio/business_advisor_sample.wav",
  },
  {
    name: "Tech Support",
    description: "Discuss tech-related issues and explore digital tools. We can talk about efficient use of technology in daily life and troubleshoot common tech problems.",
    avatar: "/assets/images/tech_support.jpg",
    audioSample: "/assets/audio/tech_support_sample.wav",
  },
  {
    name: "Legal Advisor",
    description: "Discuss basic legal concepts and common legal scenarios. I can help clarify legal terminology and provide general information about individual rights.",
    avatar: "/assets/images/legal_advisor.jpg",
    audioSample: "/assets/audio/legal_advisor_sample.wav",
  }
];

// Component for displaying advisors
const AdvisorGallery: React.FC<{ id?: string }> = ({ id }) => {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
  const [playingAdvisor, setPlayingAdvisor] = useState<string | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleAudio = (advisor: Advisor) => {
    if (currentAudio && playingAdvisor === advisor.name) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingAdvisor(null);
    } else {
      if (currentAudio) {
        currentAudio.pause();
      }
      const newAudio = new Audio(advisor.audioSample);
      newAudio.onerror = () => console.error(`Failed to load audio: ${advisor.audioSample}`);
      setCurrentAudio(newAudio);
      setPlayingAdvisor(advisor.name);
      newAudio.play().catch(error => console.error('Error playing audio:', error));
    }
  };

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const newScrollPosition = scrollContainerRef.current.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
      scrollContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section id={id} className={`py-20 ${homeConfig.advisorGallery.backgroundColor} relative`}>
      <div className="container mx-auto px-4 relative">
        <h2 className="text-3xl font-bold text-center mb-6">Your AI Conversation Partners</h2>
        <p className="text-center text-lg mb-12 max-w-2xl mx-auto">
          Our AI advisors are here to listen and chat about anything on your mind. Each one brings a unique perspective to your conversations.
        </p>
        
        {/* Desktop scroll buttons */}
        <button
          onClick={() => scroll('left')}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-md z-10 hidden md:block"
          style={{ left: '-40px' }}
          aria-label="Scroll left"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <button
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-md z-10 hidden md:block"
          style={{ right: '-40px' }}
          aria-label="Scroll right"
        >
          <ChevronRight className="w-6 h-6" />
        </button>

        <div className="relative overflow-hidden">
          <div 
            ref={scrollContainerRef}
            className="flex overflow-x-auto space-x-8 pb-8 pt-4 px-4 -mx-4 scrollbar-hide"
            style={{ scrollBehavior: 'smooth' }}
          >
            {advisors.map((advisor) => (
              <div
                key={advisor.name}
                className={`${homeConfig.advisorGallery.cardBackgroundColor} p-6 rounded-lg shadow-lg text-center min-w-[280px] max-w-[300px] flex flex-col justify-between transition-all duration-300 hover:shadow-xl hover:-translate-y-1`}
              >
                <div>
                  <img
                    src={advisor.avatar}
                    alt={`${advisor.name}`}
                    className="w-24 h-24 rounded-full mx-auto mb-4 object-cover border-4 border-primary/20"
                  />
                  <h3 className="text-xl font-semibold mb-2">{advisor.name}</h3>
                  <p className="text-sm text-muted-foreground mb-4">{advisor.description}</p>
                </div>
                <button
                  onClick={() => handleAudio(advisor)}
                  className="mt-4 bg-primary text-primary-foreground py-2 px-4 rounded-full text-sm font-bold hover:bg-primary/90 transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg"
                >
                  {playingAdvisor === advisor.name ? (
                    <><Pause className="w-4 h-4 mr-2" /> Pause Sample</>
                  ) : (
                    <><Play className="w-4 h-4 mr-2" /> Hear a Sample</>
                  )}
                </button>
              </div>
            ))}
          </div>

          {/* Mobile scroll buttons */}
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-md z-10 md:hidden"
            aria-label="Scroll left"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-md z-10 md:hidden"
            aria-label="Scroll right"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default AdvisorGallery;