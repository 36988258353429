import React from 'react';
import { FaUserFriends, FaClock, FaShieldAlt, FaUsersCog, FaGlobe, FaHeadphones } from 'react-icons/fa';
import { homeConfig } from '../../config/HomeConfig';

interface FeaturesProps {
  id?: string;
}

const FeatureCard: React.FC<{ title: string; description: string; icon: React.ReactNode }> = ({ title, description, icon }) => (
  <div className={`${homeConfig.features.cardBackgroundColor} p-6 rounded-lg shadow-md transition duration-300 hover:shadow-xl`}>
    <div className={`${homeConfig.features.iconColor} mb-4`}>{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-muted-foreground">{description}</p>
  </div>
);

export const Features: React.FC<FeaturesProps> = ({ id = 'features' }) => (
  <section id={id} className={`py-20 ${homeConfig.features.backgroundColor}`}>
    <div className="container mx-auto px-4">
      <h2 className={`text-3xl font-bold text-center mb-12 ${homeConfig.features.titleColor}`}>
        {homeConfig.features.title}
      </h2>
      <div className={`grid ${homeConfig.features.gridLayout} gap-8`}>
        <FeatureCard
          title="Judgment Free"
          description="Share your thoughts freely in a safe space. Our AI advisors listen without prejudice or criticism."
          icon={<FaUserFriends size={48} />}
        />
        <FeatureCard
          title="Always Available"
          description="3 AM doubts? Midday crisis? Our advisors are here 24/7, ready to listen and offer guidance."
          icon={<FaClock size={48} />}
        />
        <FeatureCard
          title="Your Privacy, Protected"
          description="What stays between you and our advisors, stays between you and our advisors. Period."
          icon={<FaShieldAlt size={48} />}
        />
        <FeatureCard
          title="Personalized Expertise"
          description="Connect with AI advisors who specialize in areas like wellness, career, and relationships."
          icon={<FaUsersCog size={48} />}
          />
        <FeatureCard
          title="Accessible Anywhere"
          description="Use our service on any device, anytime. Your personal advisor is just a tap away."
          icon={<FaGlobe size={48} />}
        />
        <FeatureCard
          title="Voice-Based Interaction"
          description="Enjoy natural, voice-based conversations for a more personal and engaging experience."
          icon={<FaHeadphones size={48} />}
        />
      </div>
    </div>
  </section>
);