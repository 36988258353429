import React, { useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import PurchaseModal from './PurchaseModal';

const BuyMinutesButton: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isSignedIn, isLoaded } = useUser();

  const handleClick = () => {
    if (isSignedIn) {
      setIsModalOpen(true);
    }
    // If not signed in, the button should not be clickable or visible
  };

  if (!isLoaded || !isSignedIn) {
    return null; // Don't render anything if not loaded or not signed in
  }

  return (
    <>
      <button
        onClick={handleClick}
        className="border border-blue-500 text-blue-500 dark:border-blue-400 dark:text-blue-400 px-4 py-2 rounded-lg hover:bg-blue-500 hover:text-white dark:hover:bg-blue-400/10 transition duration-300"
      >
        Buy Minutes
      </button>
      <PurchaseModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default BuyMinutesButton;